import css from '@styled-system/css'
import styled from '@emotion/styled'

export const CasinoSkyLogotype = styled.div`
  ${css({
    width: ['125px', null, '140px'],
    height: ['30px', null, '45px'],
    backgroundImage: [
      'url(/images/casinosky-logo-small.png)',
      null,
      'url(/images/casinosky-logo.png)',
    ],
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  })};

  @media screen and (max-width: 320px) {
    background-image: url(/images/casinosky-logo-small.png);
    width: 80px;
    height: 50px;
  }
`

// for @loadable/components
export default CasinoSkyLogotype
